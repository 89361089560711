<template>
  <section id="producers">
    <b-card>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :current-page="producersTable.currentPage"
        :items="producersTable.items"
        :fields="producersTable.fields"
        :sort-by.sync="producersTable.sortBy"
        :sort-desc.sync="producersTable.sortDesc"
        :sort-direction="producersTable.sortDirection"
        :filter="producersTable.filter"
        :filter-included-fields="producersTable.filterOn"
      >

      <template #cell(email)="data">
        <div v-if="data.value">
          <b-badge variant="light-primary">
            {{data.value}}
          </b-badge>
        </div>
      </template>

      <template #cell(out_of_stock_notification)="data">
        <b-form-checkbox
          v-model="data.value"
          class="custom-control-success"
          :key="data.item.id"
          @change="handleCheckboxChange(data.item.id, data.field.key, data.value)"
        >
        </b-form-checkbox>
      </template>

      <template #cell(end_order_cycle_notification)="data">
        <b-form-checkbox
          v-model="data.value"
          class="custom-control-success"
          :key="data.item.id"
          @change="handleCheckboxChange(data.item.id, data.field.key, data.value)"
        >
        </b-form-checkbox>
      </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="producersTable.currentPage"
            :total-rows="producersTable.totalRows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BTable,
  BPagination,
  BCardBody,
  BCard,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import axios from "axios"

export default {
  components: {
    BTable,
    BPagination,
    BCardBody,
    BCard,
    BBadge,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      producersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: "id",
        sortDesc: true,
        sortDirection: "desc",
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: '',
          content: '',
        },
        fields: [
          { key: "id", label: "id", sortable: true },
          { key: "name", label: "name", sortable: true },
          { key: "ofn_id", label: "ofn id", sortable: true },
          { key: "email", label: "email", sortable: true, },
          { key: "out_of_stock_notification", label: "out of stock notification", sortable: true },
          { key: "end_order_cycle_notification", label: "end order cycle notification", sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      queryParams: {},
    }
  },
  methods: {
    getProducers() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/producers/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
          params: this.queryParams,
        })
        .then(response => {
          this.producersTable.items = response.data.results
          this.producersTable.totalRows = response.data.count * 2
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    handlePageChange(value) {
      this.queryParams["limit"] = 10
      this.queryParams["offset"] = (value-1) * 10
      this.getProducers()
    },
    handleCheckboxChange(id, key, value) {
      var params = {}
      params[key] = value

      axios
        .patch(process.env.VUE_APP_ROOT_API + "/producers/" + id + "/", params, {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
  },
  created() {
    this.getProducers()
  }
}
</script>
